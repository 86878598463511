<template>
    <data-table-view
        endpoint="requests"
        headline="Anfragen"
        :fixed-query-params="this.company ? {'customer': this.company['@id']} : {}"
    >
        <template slot="actions">
            <router-link
                v-if="this.company"
                :to="'/requests/create/intern?companyId=' + this.company.id">
                <Button label="Anfrage anlegen"></Button>
            </router-link>
        </template>

        <Column field="internalName" header="Interner Name" sortable/>
        <Column v-if="!this.company" header="Kunde">
            <template #body="slotProps">
                {{slotProps.data.customer.name}}
            </template>
        </Column>
        <Column header="Wirkung und Art">
            <template #body="slotProps">
                {{slotProps.data.typeCategory.name}}
                <br>
                {{slotProps.data.impactBhiCategory.name}}
            </template>
        </Column>
        <Column header="Attribute, Ziel & Wirkung">
            <template #body="slotProps">
                <ul>
                    <li v-for="category in slotProps.data.targetCategories">
                        {{ category.name }}
                    </li>
                </ul>
            </template>
        </Column>
        <Column header="Attribute, Ziel & Wirkung">
            <template #body="slotProps">
                <ul>
                    <li v-for="category in slotProps.data.workingEnvironmentCategories">
                        {{ category.name }}
                    </li>
                </ul>
            </template>
        </Column>
        <Column header="Aktionen" :styles="{'text-align': 'right'}">
            <template #body="slotProps">
                <router-link :to="'/requests/' + slotProps.data.id">
                    <i class="fi-rr-pencil"></i>
                </router-link>
            </template>
        </Column>
    </data-table-view>
</template>
<script>
    import Button from "primevue/button/Button";
    import DataTableView from "@/components/DataTableView";
    import Column from "primevue/column/Column";

    export default {
        props: {
            company: {
                required: false,
                default: () => null
            }
        },
        components: {
            Button,
            Column,
            DataTableView
        }
    }
</script>
