<template>
    <div v-if="entity.id">
        <update-view-header
            :headline="entity.name"
            button-text="Zurück zur Übersicht"
            button-path="/customers"
        />
        <TabView>
            <TabPanel header="Allgemeine Daten">
                <div class="surface-card p-6 pb-5 border-1 surface-border">
                    <update-form
                        v-model="editedEntity"
                        :errors="editedEntityErrors"
                        type="customer"
                        :show-main-contact="false"
                    />

                    <div class="surface-border border-top-1 opacity-50 mb-4 mt-4 py-0 col-12"></div>

                    <div class="text-center">
                        <Button type="submit" label="Aktualisieren" @click="submitEntity"></Button>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Ansprechpartner">
                <div class="surface-card p-6 pb-5 border-1 surface-border">
                    <person-data-table :company="entity"/>
                </div>
            </TabPanel>
            <TabPanel header="Anfragen">
                <div class="surface-card p-6 pb-5 border-1 surface-border">
                    <request-data-table :company="entity"/>
                </div>
            </TabPanel>
            <TabPanel header="Reporting & Abrechnung">
                <no-module/>
            </TabPanel>
        </TabView>
    </div>
</template>

<script>
    import TabView from 'primevue/tabview';
    import TabPanel from 'primevue/tabpanel';
    import Button from 'primevue/button';
    import Column from 'primevue/column';
    import DataTableView from "@/components/DataTableView";
    import InputText from "primevue/inputtext/InputText";
    import UpdateForm from "@/components/data/company/UpdateForm";
    import NoModule from "@/components/NoModule";
    import UpdateViewHeader from "@/components/view/UpdateViewHeader";
    import UpdateView from "@/mixin/UpdateView";
    import PersonDataTable from "@/components/data/person/PersonDataTable";
    import RequestDataTable from '@/components/data/request/DataTable';

    export default {
        mixins: [UpdateView],
        methods: {
            getApiEndpoint() {
                return 'companies';
            }
        },
        components: {
            PersonDataTable,
            UpdateViewHeader,
            NoModule,
            UpdateForm,
            InputText,
            Button,
            DataTableView,
            Column,
            TabView,
            TabPanel,
            RequestDataTable
        },
    }
</script>
