<template>
    <div>
        <Dropdown
            v-if="!multiple"
            :options="options"
            v-model="internValue"
            :optionLabel="entityOptionLabel"
            :placeholder="placeholder"
            :filter="true"
            style="width: 100%;"
            option-value="@id"
            display="chip"
            @filter="filter"
        />
        <MultiSelect
            v-if="multiple"
            v-model="internValue"
            :options="options"
            :optionLabel="entityOptionLabel"
            :placeholder="placeholder"
            :filter="true"
            style="width: 100%;"
            option-value="@id"
            display="chip"
            @filter="filter"
        >
        </MultiSelect>
    </div>
</template>

<script>
    import MultiSelect from 'primevue/multiselect';
    import Dropdown from 'primevue/dropdown';

    export default {
        components: {
            Dropdown,
            MultiSelect,
        },
        props: {
            type: {
                type: String,
                required: true,
            },
            extraOptions: {
                type: Object,
                required: false,
            },
            multiple: {
                type: Boolean,
                required: false,
                default: () => true
            },
            value: {
                type: [Object, Array],
            },
            placeholder: {
                type: String,
                required: false,
                default: () => ''
            },
        },
        data() {
            return {
                q: null,
                internValue: [],

                entityEndpoint: null,
                entityOptionLabel: 'name',
                entityEndpointExtraParams: {},
                entityEndpointSearchParam: '',

                options: [],
            }
        },
        mounted() {
            switch (this.type) {
                case 'status':
                    this.entityEndpoint = 'statuses';
                    this.entityOptionLabel = (item) => 'name' in item ? item['name'] : null;
                    this.entityEndpointSearchParam = 'name';
                    break;
                case 'group':
                    this.entityEndpoint = 'user-groups';
                    this.entityOptionLabel = (item) => 'name' in item ? item['name'] : null;
                    this.entityEndpointExtraParams = {active: true};
                    this.entityEndpointSearchParam = 'name';
                    break;
                case 'user-real':
                    this.entityEndpoint = 'user-reals';
                    this.entityOptionLabel = function (entity) {
                        if (!('active' in entity && 'displayName' in entity)) {
                            return null;
                        }

                        if (!entity.active) {
                            return entity['displayName'] + ' (inaktiv)';
                        }
                        return entity['displayName'];
                    }
                    this.entityEndpointExtraParams = {active: true};
                    this.entityEndpointSearchParam = 'username';
            }

            if (this.value) {
                if(this.multiple) {
                    this.value.forEach((item) => {
                        if (typeof item === 'object') {
                            this.internValue.push(item['@id']);
                        } else {
                            this.internValue.push(item);
                        }
                        this.addOption(item);
                    });
                } else {
                    if (typeof this.value === 'object') {
                        this.internValue = this.value['@id'];
                    } else {
                        this.internValue = this.value;
                    }
                    this.addOption(this.value);
                }

            }
            this.loadOptions();
        },
        methods: {
            filter(event) {
                this.q = event.value;
                this.loadOptions();
            },
            addOption(entity) {
                if (typeof entity === 'object' && this.entityOptionLabel(entity) !== null) {
                    if (this.options.map((item) => item['@id']).indexOf(entity['@id']) === -1) {
                        this.options.push(entity);
                    }
                } else {
                    let entityIdentifier = (typeof entity === 'object' ? entity['@id'] : entity).substr(1);
                    this.$http.get(entityIdentifier).then(response => {
                        let entity = response.body;
                        if (this.options.map((item) => item['@id']).indexOf(entity['@id']) === -1) {
                            this.options.push(entity);
                        }
                    });
                }
            },
            loadOptions() {
                let params = {};
                if(this.q && this.q.length > 0) {
                    params[this.entityEndpointSearchParam] = this.q;
                }
                this.$http.get(this.entityEndpoint, {params: params}).then(response => {
                    response.data['hydra:member'].forEach((item) => {
                        this.addOption(item);
                    });
                });
            }
        },
        watch: {
            'internValue': {
                deep: true,
                handler: function (newVal) {
                    this.$emit('input', newVal);
                }
            }
        }
    }
</script>
