<template>
    <div v-if="company">
        <person-edit-dialog ref="personEditDialog" :company="company" @update="$refs.dataTableView.reload();"/>
        <data-table-view
            endpoint="people"
            headline="Ansprechpartner"
            ref="dataTableView"
            :fixed-query-params="{company: company['@id']}"
        >
            <template slot="actions">
                <a @click="$refs.personEditDialog.open()">
                    <Button
                        label="Ansprechpartner ergänzen"
                        class="p-button-primary p-button-sm"
                    />
                </a>
            </template>
            <Column field="lastName" header="Name" sortable>
                <template #body="slotProps">
                    {{ slotProps.data.firstName }}
                    {{ slotProps.data.lastName }}
                </template>
            </Column>
            <Column field="email" header="E-Mail" sortable/>
            <Column field="phone" header="Telefonnummer"/>
            <Column field="userReal" header="Frontend-Account">
                <template #body="slotProps">
                    <template v-if="slotProps.data.userReal">
                        Ja
                    </template>
                </template>
            </Column>
            <Column header="" :styles="{'text-align': 'right'}">
                <template #body="slotProps">
                    <a
                        @click="$refs.personEditDialog.open(slotProps.data)"
                        v-if="slotProps.data.userCanUpdate"
                    >
                        <Button
                            label="Bearbeiten"
                            class="p-button-secondary p-button-sm cursor-pointer"
                        />
                    </a>
                </template>
            </Column>
        </data-table-view>
    </div>
</template>

<script>
    import Button from "primevue/button/Button";
    import Column from "primevue/column";
    import DataTableView from "@/components/DataTableView";
    import PersonEditDialog from "@/components/data/person/PersonEditDialog";

    export default {
        props: {
            company: {
                type: Object,
                required: true,
            }
        },
        components: {PersonEditDialog, Button, Column, DataTableView}
    }
</script>
