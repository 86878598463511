<template>
    <Dialog :visible.sync="visible"
            :modal="true"
            :maximizable="true"
            :header="value.id ? 'Ansprechpartner bearbeiten' : 'Ansprechpartner hinzufügen'"
            :closable="true"
    >
        <div class="block min-w-40 pt-4 pb-2">
            <update-form v-model="value" :errors="errors"/>
        </div>

        <template #footer>
            <div class="text-center">
                <Button class="p-button" @click="submitEntity">
                    <template v-if="value.id">Aktualisieren</template>
                    <template v-else>Erstellen</template>
                </Button>
            </div>
        </template>
    </Dialog>
</template>

<script>
    import InternUpdateView from "@/mixin/InternUpdateView";
    import UpdateForm from "@/components/data/person/UpdateForm";
    import Dialog from 'primevue/dialog';

    export default {
        components: {UpdateForm, Dialog},
        mixins: [InternUpdateView],
        data() {
            return {
                visible: false,
            }
        },
        props: {
            company: {
                type: Object,
                required: true,
            }
        },
        methods: {
            getUpdateAction() {
                this.visible = false;
            },
            getApiEndpoint() {
                return 'people';
            },
            open(entity = null) {
                this.errors = {};
                if (entity !== null) {
                    this.loadEntity(entity);
                } else {
                    this.value = {
                        company: this.company['@id']
                    };
                }
                this.visible = true;
            }
        }
    }
</script>
